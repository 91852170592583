import styled from "@emotion/styled";
import { ReactElement } from "react";

const StyledListing = styled.ul`
  margin: 0;
  line-height: 1.9;
  padding-left: 20px;
  li {
    line-height: 1.9;
    position: relative;
    &::marker {
      color: #E89A5E;
      font-size: 1.6em;
    }
  }
`;

interface ListingProps {
    items: Array<string | ReactElement>;
}

const Listing = ({ items }: ListingProps) => (
    <StyledListing>
        {items.map((i, idx) => <li key={`${idx}_listing`}>
            {i}
        </li>)}
    </StyledListing>
);

export default Listing;