import styled from "@emotion/styled";
import { useNavigate } from 'react-router-dom';

const StyledLogo = styled.div`
  border-radius: 50%;
  height: 70px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const StyledTextLogo = styled.div`
  width: 54px;
  height: 40px;
  background-color: #2d6a4f;
  color: #ffffff;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

const StyledLogoDescription = styled.span`
  font-size: 22px;
  color: #2d6a4f;
  margin-left: 10px;
  font-weight: 500;
  white-space: nowrap;
`;

interface LogoProps {
    showDescription?: boolean
}

const Logo = ({ showDescription }: LogoProps) => {
    const navigate = useNavigate();
    const clickHandler = () => {
        navigate('/');
    };

    return (
        <StyledLogo onClick={clickHandler}>
            <StyledTextLogo>PH</StyledTextLogo>
            {showDescription && (
                <StyledLogoDescription>
                    Physio&ndash;Havei&ndash;Tirol
                </StyledLogoDescription>
            )}
        </StyledLogo>
    );
}

export default Logo;