import { BrowserRouter as Router } from 'react-router-dom';
import './App.scss';
import AppRouter from "./AppRouter";
import { ScrollToTop } from "components/atoms";


const App = () => {
  return (
      <div className="App" id={'outer-container'}>
        <Router>
            <ScrollToTop />
          <AppRouter />
        </Router>
      </div>
  );
}

export default App;