import { useLocation } from "react-router-dom";
import { Header, Footer } from 'components/organisms';
import { PageBanner } from "components/atoms";
import { ITemplateProps } from 'types';

interface PageTemplateProps {
    titleText: string;
    subTitleText?: string;
}

const PageTemplate = ({ children, titleText, subTitleText }: PageTemplateProps & ITemplateProps) => {
    const { pathname } = useLocation();
    return (
        <>
            <Header />
            {pathname !== '/' ?
                <PageBanner
                    titleText={titleText}
                    subTitleText={subTitleText}
                /> : null}
            <div id="page-wrap">
                {children}
            </div>
            <Footer />
        </>
    );
};

export default PageTemplate;