import {useNavigate} from "react-router-dom";
import { PageTemplate } from 'components/templates';
import { PriceBox, PassageTitle } from "components/atoms";
import { StyledPricesWrapper, StyledTextContainer } from './styled';

const Prices = () => {
    const navigate = useNavigate();
    const bookTherapyFn = () => {
        navigate('/kontakt#contactForm');
    }
    return (
        <PageTemplate
            titleText={'Preise'}
            subTitleText={'Was kostet Physiotherapie als Hausbesuch?'}
        >
            <div className="inner-content">
                <StyledPricesWrapper>
                    <PriceBox
                        title={'45 Minuten'}
                        price={'€120'}
                        description={'Physiotherapie 45 Minuten inkl. Anfahrt'}
                        bookFn={bookTherapyFn}
                    />
                    <PriceBox
                        title={'60 Minuten'}
                        price={'€140'}
                        description={'Physiotherapie 60 Minuten inkl. Anfahrt'}
                        bookFn={bookTherapyFn}
                    />
                </StyledPricesWrapper>
                <StyledTextContainer>
                    <div className="price-note">
                        <span><sup>*</sup>alle Preise exklusive Mehrwertsteuer</span>
                        <span><sup>**</sup>Änderungen bleiben vorbehalten</span>
                    </div>
                    <br/>
                    <div className="price-declaration">
                        <p>
                            Das Honorar ist direkt nach der Rechnungslegung zu entrichten.
                        </p>
                        <p>
                            Bitte teilen Sie mir spätestens 24 Stunden vorher mit, wenn Sie einen Termin nicht einhalten
                            können. Bei einer zu späten Absage oder bei Nichterscheinen zu einem Termin, wird die
                            Therapieeinheit aus organisatorischen Gründen in Rechnung gestellt.
                        </p>
                    </div>
                    <br />
                    <div className="price-refund">
                        <PassageTitle titleText={'Wie erhalte ich meine Kostenerstattung?'} />
                        <p>
                            Nach Ende der Therapie erhalten Sie von mir eine Honorarnote. Diese reichen Sie gemeinsam
                            mit Ihrer bewilligten Verordnung und Ihrem Antrag auf Kostenerstattung bei Ihrer
                            Krankenkasse ein.
                            Wenn Sie eine Zusatzversicherung haben, sind Kostenerstattungen bis zu 100% der
                            Behandlungskosten möglich.<br />
                            Im Rahmen ihres Jahresausgleiches kann der Selbstbehalt geltend gemacht werden.

                        </p>
                    </div>
                </StyledTextContainer>
            </div>
        </PageTemplate>
    );
}

export default Prices;

