import styled from "@emotion/styled";

export const StyledPricesWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  div:first-of-type {
    margin-right: 40px;
  }
  margin-bottom: 10px;
  @media (max-width: 768px) {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    div:first-of-type {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`;

export const StyledTextContainer = styled.div`
  display: block;
  margin-top: 20px;
  .price-note {
    text-align: center;
    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }
  }
  .price-declaration {
    max-width: 768px;
    margin: auto;
    line-height: 1.9;
    padding: 0 10px;
  }
  .price-refund {
    margin-bottom: 100px;
    text-align: center;
    p {
      max-width: 768px;
      margin: 40px auto;
      line-height: 1.9;
      padding: 0 10px;
    }
  }
`;