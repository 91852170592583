import styled from "@emotion/styled";

const StyledBanner = styled.div`
  width: 100%;
  height: 240px;
  padding: 20px;
  border-radius: 8px;
  background-image: linear-gradient(107deg, #74c69d, #2d6a4f 47%, #1b4332);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  .banner__title {
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #E89A5E;
  }
  .banner__sub-title {
    font-size: 16px;
    font-weight: 300;
  }
  @media (max-width: 768px) {
    height: 180px;
    padding: 15px;
    .banner__title {
      font-size: 30px;
    }
    .banner__sub-title {
      font-size: 14px;
    }
  }
`;

interface PageBannerProps {
    titleText: string;
    subTitleText?: string;
}
const PageBanner = ({ titleText, subTitleText }: PageBannerProps) => (
    <div className={'inner-content'}>
        <StyledBanner className={'banner'}>
            <div className="banner__title">{titleText}</div>
            <div className="banner__sub-title">{subTitleText}</div>
        </StyledBanner>
    </div>
);

export default PageBanner;