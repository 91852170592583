import { Icon } from 'components/atoms';
import {
    StyledFooterWrapper,
    StyledFooterContent,
    StyledCopyrightLine,
    StyledContactWrapper,
    StyledContactItem,
    StyledSocialWrapper,
    StyledMoreInfoWrapper,
    StyledMoreInfoBlock,
    StyledMoreInfoBlockTitle,
    StyledMoreInfoBlockItem
} from './styled';
import { footerData } from 'services';

const Footer = () => (
    <StyledFooterWrapper>
        <StyledFooterContent className="inner-content">
            <StyledContactWrapper>
                {footerData.contactInfo.map(item => (
                    <StyledContactItem key={item.label}>
                        <span><Icon name={item.icon} /></span>
                        <span>{item.label}</span>
                    </StyledContactItem>
                ))}
                <StyledSocialWrapper>
                    {footerData.socialMedia.map(soc => (
                        <a href={soc.link} key={soc.link} target="_blank" rel="noreferrer">
                            <Icon name={soc.icon} />
                        </a>
                    ))}
                </StyledSocialWrapper>
            </StyledContactWrapper>
            <StyledMoreInfoWrapper>
                {footerData.moreInfo.map(info => (
                    <StyledMoreInfoBlock key={info.title}>
                        <StyledMoreInfoBlockTitle>
                            {info.title}
                        </StyledMoreInfoBlockTitle>
                        {info.links.map(link => (
                            <StyledMoreInfoBlockItem to={link.url} key={link.label}>
                                {link.label}
                            </StyledMoreInfoBlockItem>
                        ))}
                    </StyledMoreInfoBlock>
                ))}
            </StyledMoreInfoWrapper>
        </StyledFooterContent>
        <StyledCopyrightLine>
        <span style={{textAlign: 'center'}} className="inner-content">
          © Copyright Physiotherapie Havei 2022. All Right Reserved. Designed and Developed by Sammyeby
        </span>
        </StyledCopyrightLine>
    </StyledFooterWrapper>
);

export default Footer;