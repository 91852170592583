import styled from "@emotion/styled";

const StyledPassageTitle = styled.h2`
  font-size: 26px;
  font-weight: bold;
  color: #1b4332;
  position: relative;
  &:before {
    content: ' ';
    position: absolute;
    height: 6px;
    width: 100px;
    background-color: #E89A5E;
    bottom: -10px;
  }
`;

interface PassageTitleProps {
    titleText: string;
}

const PassageTitle = ({ titleText }: PassageTitleProps) => (
    <StyledPassageTitle>
        {titleText}
    </StyledPassageTitle>
);

export default PassageTitle;