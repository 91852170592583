import styled from "@emotion/styled";

const StyledImagePolaroidFrame = styled.div`
  display: flex;
  width: 550px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: 10px solid #ffffff;
  border-bottom: 64px solid #fff;
  position: relative;
  
  img {
    height: 100%;
    width: 100%;
  }
  p {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: -64px;
    font: 400 22px/1 'Kaushan Script',cursive;
    color: #2d6a4f;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    width: 350px;
    border-bottom: 45px solid #fff;
    p {
      bottom: -45px;
      font: 400 16px/1 'Kaushan Script',cursive;
    }
  }
  @media (max-width: 280px) {
    width: 250px;
    border-bottom: 45px solid #fff;
    p {
      bottom: -45px;
      font: 400 14px/1 'Kaushan Script',cursive;
    }
  }
`;


interface ImagePolaroidFrameProps {
    imgSrc: string;
    imgLabel: string;
    imgAlt?: string;
}

const ImagePolaroidFrame = ({ imgSrc, imgLabel, imgAlt }: ImagePolaroidFrameProps) => (
    <StyledImagePolaroidFrame className="image-polaroid-frame">
        <img src={imgSrc} alt={imgAlt} />
        <p>{imgLabel}</p>
    </StyledImagePolaroidFrame>
)

export default ImagePolaroidFrame