import { PageTemplate } from 'components/templates';
import { ImagePolaroidFrame, Listing, PassageTitle } from 'components/atoms';
import OFFER_IMG from 'assets/images/therapie_session_3.jpeg';
import METHODS_IMG from 'assets/images/therapie_session_1.jpeg';
import SPECIALTY_IMG from 'assets/images/therapie_session_2.jpeg';
import { StyledTherapyOffers } from './styled';

const TherapyOffers = () => (
    <PageTemplate
        titleText={'Therapieangebote'}
        subTitleText={'Damit Sie sich therapeutisch und menschlich gut aufgehoben fühlen, biete ich Ihnen folgende Leistungen an:'}
    >
        <StyledTherapyOffers className="inner-content">
            <section id="myOffers" className="my-offers">
                <div className="offers-text-content">
                    <PassageTitle titleText={'Meine Angebote'} />
                    <Listing items={[
                        'Physiotherapie',
                        'Krankengymnastik',
                        'Orthopädische Krankheitsbilder',
                        'Behandlung nach Operationen und Verletzungen',
                        'Internistische Krankheitsbilder',
                        'Geriatrische Symptomatiken',
                        'Ergonomie am Arbeitsplatz und Zuhause',
                    ]} />
                </div>
                <ImagePolaroidFrame imgSrc={OFFER_IMG} imgLabel="Meine Angebote" />
            </section>
            <section id="mySpecialty" className="my-specialty">
                <ImagePolaroidFrame imgSrc={SPECIALTY_IMG} imgLabel="Meine Spezialisierungen" />
                <div className="offers-text-content">
                    <PassageTitle titleText={'Meine Spezialisierungen'} />
                    <Listing items={[
                        'CMD - Craniomandibuläre Dysfunktion',
                        'Neurologische Krankheitsbilder',
                        'Gangschulung',
                    ]} />
                </div>
            </section>
            <section id="myMethods">
                <div className="offers-text-content">
                    <PassageTitle titleText={'Meine Methoden'} />
                    <Listing items={[
                        'Manuelle Therapie nach dem Maitland- und Kaltenborn-Konzept',
                        'Funktionelle Bewegungslehre Klein-Vogelbach',
                        'Neurokognitive Rehabilitation nach Prof. Carlo Perfetti',
                        'Manuelle Lymphdrainage nach Dr. Vodder',
                        'Heilmassage',
                    ]} />
                </div>
                <ImagePolaroidFrame imgSrc={METHODS_IMG} imgLabel="Meine Methoden" />
            </section>
        </StyledTherapyOffers>
    </PageTemplate>
);

export default TherapyOffers;