import styled from "@emotion/styled";
import { PageTemplate } from "components/templates";
import { Link } from 'components/atoms';

const StyledImpressum = styled.div`
  margin-top: 30px;
  > div {
    max-width: 400px;
    margin: auto;
    div {
      margin-bottom: 10px;
      font-size: 18px;
      a {
        font-size: 16px;
      }
    }
  }
`;

const Impressum = () => (
    <PageTemplate titleText={'Impressum'}>
        <StyledImpressum className={'inner-content'}>
            <div>
                <h3>
                    Physiotherapie Havei Tirol
                </h3>
                <div>Claudiu Havei</div>
                <div>Tschirgantweg 11</div>
                <div>6432 Sautens</div>
                <div>
                    <Link href="tel:+4369917090872">+43 (0) 699 17090872</Link>
                </div>
                <div>
                    <Link href="mailto:info@physiotherapie-havei.at">info@physiotherapie-havei.at</Link>
                </div>
                <div>
                    <Link href="https://physiotherapie-havei.at">www.physiotherapie-havei.at</Link>
                </div>
            </div>
        </StyledImpressum>
    </PageTemplate>
);

export default Impressum;

