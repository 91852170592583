import styled from "@emotion/styled";
import {NavLink, NavLinkProps} from "react-router-dom";

const NavBarItem = ({ className, to, children }: NavLinkProps) => (
    <NavLink className={className} to={to}>{children}</NavLink>
)

export const StyledNavBarItem = styled(NavBarItem)`
  width: 100%;
  color: #2d6a4f;
  text-decoration: none;
  text-align: center;
  transition: all .3s;
  border-bottom: 2px solid transparent;
  white-space: nowrap;
  &.active {
    border-bottom-color: #E89A5E;
  }
  &:hover {
    text-underline: none;
    border-bottom-color: #E89A5E;
  }
  padding: 0.7em 1em;
`;