const NotFoundPage = () => (
    <div style={{
        height: '100%',
        width: '100%'
    }}>
        <h2>404</h2>
        <h2>Sorry, Seite nicht gefunden :( </h2>
    </div>
);

export default NotFoundPage;