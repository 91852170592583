import styled from "@emotion/styled";
// import { HTMLProps } from "react";

const Link = styled.a`
  font-size: 14px;
  color: #2d6a4f;
  text-decoration: none;
  font-weight: normal;
  line-height: 1.75;
  &:hover {
    text-decoration: underline;
  }
`;

// const Link = (props: HTMLProps<HTMLAnchorElement>) => (
//     <StyledLink {...props}>{props.children}</StyledLink>
// );

export default Link