import { Button, darken } from "@mui/material";
import styled from "@emotion/styled";


export const StyledButton = styled(Button)`
  font-size: 14px;
  text-transform: none;
  background-color: #40916c;
  color: #fff;
  &:hover {
    background-color: #52b788;
  }
  &.secondary {
    background-color: #E89A5E;
    &:hover {
      background-color: ${darken('#E89A5E', 0.1)};
    }
  }
`;