import { useState, useEffect, useRef } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useMediaQuery } from '@mui/material';
import axios from "axios";
import { PageTemplate } from 'components/templates';
import {TextInputField, StyledButton, Icon, Link} from 'components/atoms';
import { StyledWrapper, StyledTextWrapper, StyledForm, StyledSuccessMessage } from './styled';
import { useLocation } from "react-router-dom";

interface IFormInputs {
    firstName: string;
    lastName: string;
    email: string;
    telephone: string;
    address: string;
    message: string;
    subject: string;
    projectKey: string;
}
const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+''))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const PHONE_PATTERN = /^(?:\+|0|\(\d+\)) ?(?:[0-9] ?){6,14}[0-9]$/;

const Contact = () => {
    const [formStatus, setFormStatus] = useState('unsent');
    const successComponentRef = useRef<HTMLDivElement | null>(null);
    const formRef = useRef<HTMLFormElement | null>(null);
    const { control, formState: { errors },  handleSubmit } = useForm<IFormInputs>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            telephone: '',
            address: '',
            message: '',
            subject: 'Physiotherapie Anfrage',
            projectKey: 'physioHavei',
        }
    });
    const isMobile = useMediaQuery('(max-width: 768px)');
    const { hash } = useLocation();

   const onSubmit: SubmitHandler<IFormInputs> = async formData => {
       setFormStatus('sending');
       formData.message = `
            <span><strong>Name: </strong></span><span>${formData.firstName} ${formData.lastName}</span><br/><br/>
            <span><strong>E-Mail: </strong></span><a href="mailto:${formData.email}">${formData.email}</a><br/><br/>
            <span><strong>Telefon: </strong></span><span>${formData.telephone}</span><br/><br/>
            <span><strong>Adresse: </strong></span><span>${formData.address}</span><br/><br/>
            <span><strong>Nachricht: </strong></span><span>${formData.message}</span><br/>
       `;

       try {
           const response = await axios.post('https://mail.samuelumeh.com/send', formData);

           const { status } = response.data;
           if (status === 'FAIL') {
               setFormStatus('error');
               return;
           }
           if (status === 'OK') {
               setFormStatus('success');
           }
       } catch (error) {
           setFormStatus('error');
       }
   };

   const scrollIntoElementView = (el: HTMLElement | null) => {
       el?.scrollIntoView({ behavior: 'smooth' })
   };

   useEffect(() => {
       if (formStatus === 'success' && isMobile && successComponentRef.current) {
           scrollIntoElementView(successComponentRef.current);
       }
   }, [formStatus, isMobile]);

   useEffect(() => {
       if (hash && hash === '#contactForm' && isMobile && formRef.current) {
           scrollIntoElementView(formRef.current);
       }
   }, [hash, isMobile]);

    return (
        <PageTemplate
            titleText={'Kontakt'}
            subTitleText={'Ich freue mich auf Ihre Anfrage!'}
        >
            <StyledWrapper className="inner-content">
                <StyledTextWrapper>
                    <h2>Liebe Patientin, lieber Patient,</h2>
                    <p>
                        ich freue mich auf Ihren Anruf oder E-Mail.
                        Ich garantiere Ihnen, dass ich mich innerhalb von 24 Stunden bezüglich einer Terminvereinbarung
                        für einen Physiotherapie-Hausbesuch bei Ihnen melde.
                    </p>
                    <br />
                    <p>
                        Ihr Physiotherapeut
                        <span className="signed-name">
                            Claudiu Havei
                        </span>
                    </p>
                    <br />
                    <br />
                    <h3>
                        Telefonisch erreichbar
                    </h3>
                    <span>
                        (+43) 069917090872
                    </span>
                    <span style={{display: 'block', marginTop: '5px'}}>15:00 bis 19:00 Uhr</span>
                    <p>
                        Gerne rufe ich Sie zurück.<br /> Bitte geben Sie hierfür Ihre Telefonnummer an.
                    </p>
                    <br />
                    <h3>
                        E-Mail
                    </h3>
                    <span>
                        <Link href="mailto:info@physiotherapie-havei.at">info@physiotherapie-havei.at</Link>
                    </span>
                </StyledTextWrapper>
                {formStatus === 'success' && (
                    <StyledSuccessMessage ref={successComponentRef}>
                        <div className="icon-wrapper">
                            <Icon style={{width: '100px', margin: 'auto'}} name="TickIcon" />
                        </div>
                        <p>Ihre Nachricht wurde erfolgreich gesendet.</p>
                    </StyledSuccessMessage>
                )}
                {formStatus !== 'success' && (
                    <StyledForm
                        noValidate id="contactForm"
                        autoComplete={'off'}
                        className={`${formStatus === 'sending' ? 'form-sending' : ''}`}
                        onSubmit={handleSubmit(onSubmit)}
                        ref={formRef}
                    >
                    <div className="form-title">
                        Termin Anfrage
                    </div>
                    <Controller
                        name="firstName"
                        control={control}
                        defaultValue={'Test name'}
                        rules={{ required: true }}
                        render={
                            ({ field }) => <TextInputField
                                {...field}
                                label="Vorname"
                                placeholder="Vorname"
                                fullWidth
                                inputRef={field.ref}
                                error={!!errors.firstName}
                                helperText={errors.firstName && 'Bitte prüfen Sie Ihre Eingabe'}
                                disabled={formStatus === 'sending'}
                            />
                        }
                    />
                    <Controller
                        name="lastName"
                        control={control}
                        defaultValue={''}
                        rules={{ required: true }}
                        render={
                            ({ field }) => <TextInputField
                                {...field}
                                label="Nachname"
                                placeholder="Nachname"
                                fullWidth
                                inputRef={field.ref}
                                error={!!errors.lastName}
                                helperText={errors.lastName && 'Bitte prüfen Sie Ihre Eingabe'}
                                disabled={formStatus === 'sending'}
                            />
                        }
                    />
                    <Controller
                        name="email"
                        control={control}
                        defaultValue={''}
                        rules={{ required: true, pattern: EMAIL_PATTERN }}
                        render={
                            ({ field }) => <TextInputField
                                {...field}
                                label="Email"
                                placeholder="Email"
                                fullWidth
                                inputRef={field.ref}
                                error={!!errors.email}
                                helperText={
                                    (errors.email && !field.value && 'Bitte prüfen Sie Ihre Eingabe') ||
                                    (errors.email && field.value && 'Bitte geben Sie eine gültige E-Mail-Adresse an')
                                }
                                disabled={formStatus === 'sending'}
                            />
                        }
                    />
                    <Controller
                        name="telephone"
                        control={control}
                        defaultValue={''}
                        rules={{ required: true, pattern: PHONE_PATTERN }}
                        render={
                            ({ field }) => <TextInputField
                                {...field}
                                label="Telefon"
                                placeholder="Telefon"
                                fullWidth
                                inputRef={field.ref}
                                error={!!errors.telephone}
                                helperText={
                                    (errors.telephone && !field.value && 'Bitte prüfen Sie Ihre Eingabe') ||
                                    (errors.telephone && field.value && 'Bitte geben Sie eine gültige Telefonnummer an')
                                }
                                disabled={formStatus === 'sending'}
                            />
                        }
                    />

                    <Controller
                        name="address"
                        control={control}
                        defaultValue={''}
                        rules={{ required: true }}
                        render={
                            ({ field }) => <TextInputField
                                {...field}
                                label="Adresse"
                                placeholder="Adresse"
                                fullWidth
                                inputRef={field.ref}
                                error={!!errors.address}
                                helperText={errors.address && 'Bitte prüfen Sie Ihre Eingabe'}
                                disabled={formStatus === 'sending'}
                            />
                        }
                    />
                    <Controller
                        name="message"
                        control={control}
                        defaultValue={''}
                        rules={{ required: true }}
                        render={
                            ({ field }) => <TextInputField
                                {...field}
                                label="Nachricht"
                                placeholder="Geben Sie Ihre Nachricht ein..."
                                fullWidth
                                multiline
                                rows={5}
                                inputRef={field.ref}
                                error={!!errors.message}
                                helperText={errors.message && 'Bitte geben Sie Ihre Nachricht ein'}
                                disabled={formStatus === 'sending'}
                            />
                        }
                    />
                    <StyledButton type="submit" disabled={formStatus === 'sending'}>
                        Absenden
                    </StyledButton>
                        {formStatus === 'error' && (
                            <p style={{color: 'red'}}>
                                Ein Fehler ist aufgetreten!<br/>
                                Bitte versuchen Sie später noch mal.
                            </p>
                        )}
                </StyledForm>)}
            </StyledWrapper>
        </PageTemplate>
    );
};

export default Contact;