import { useRoutes } from 'react-router-dom';
import { IRouteList } from 'types';
import { Contact, Home, Prices, AboutMe, TherapyOffers, MobileTherapy, Impressum, DataPrivacy, NotFoundPage } from 'components/pages';

export const routes: IRouteList = [
    {
        path: '/',
        element: <Home />,
        displayName: 'Startseite',
    },
    {
        path: '/uebermich',
        element: <AboutMe />,
        displayName: 'Über mich'
    },
    {
        path: '/angebote',
        element: <TherapyOffers />,
        displayName: 'Therapieangebote'
    },
    {
        path: '/therapie',
        element: <MobileTherapy />,
        displayName: 'Mobile Therapie'
    },
    {
        path: '/preise',
        element: <Prices />,
        displayName: 'Preise'
    },
    {
        path: '/kontakt',
        element: <Contact />,
        displayName: 'Kontakt'
    },
    {
        path: '/impressum',
        element: <Impressum />,
        displayName: 'Impressum'
    },
    {
        path: '/datenschutz',
        element: <DataPrivacy />,
        displayName: 'Datenschutz'
    },
    {
        path: "*",
        element: <NotFoundPage />
    }
];

const AppRouter = () => useRoutes(routes);

export default AppRouter;