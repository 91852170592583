import styled from "@emotion/styled";
import {Link, LinkProps } from "react-router-dom";

const MoreInfoItemLink = ({ className, to, children }: LinkProps) => (
    <Link className={className} to={to}>{children}</Link>
)

export const StyledFooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  background-color: #2d6a4f;
`;

export const StyledFooterContent = styled.div`
  min-height: 300px;
  width: 100%;
  color: #ffffff;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledCopyrightLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  background-color: #1b4332;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`;


export const StyledContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;
`;

export const StyledContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  &:nth-of-type(3) {
    margin-bottom: 50px;
  }
  span {
    &:first-of-type {
      margin-right: 8px;
      svg {
        width: 15px;
        height: 14px;
      }
    }
    &:last-of-type {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
    }
  }
`;

export const StyledSocialWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 154px;
  width: 100%;
  justify-content: space-between;
  svg {
    width: 22px;
    height: 22px;
  }
`;

export const StyledSocialItem = styled.span``;

export const StyledMoreInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 20px;
  }
`;

export const StyledMoreInfoBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 210px;
`;

export const StyledMoreInfoBlockTitle = styled.div`
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 24px;
`;

export const StyledMoreInfoBlockItem = styled(MoreInfoItemLink)`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  margin-bottom: 10px;
  transition: color .3s;
  &:hover {
    color: #FFFFFF;
  }
`;

export const StyledNewsLetterWrapper = styled.form`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  .newsletter-label {
    font-size: 14px;
    color: rgba(255,255,255, 0.6);
    display: block;
  }
  .input-button-wrapper {
    width: 100%;
    margin-top: 20px;
    button {
      background-color: #FFFFFF;
      padding: 4px 6px;
      //font-size: ;
      &:hover {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
    .MuiTextField-root {
      width: calc(100% - 65px);
      .MuiInput-root {
        &:before {
          border-bottom: 2px solid #FFFFFF;
        }
        &:after {
          border-bottom: 2px solid #FF662D;
        }
      }
    }
  }
`;



export const StyledLogoMessageWrapper = styled.div`
  max-width: 480px;
  display: flex;
  align-items: flex-start;
  a {
    padding: 0;
    max-width: 70px;
    span {
      height: 30px;
      width: 70px;
      margin-bottom: 0;
    }
  }
`;

export const StyledLogoMessage = styled.span`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  padding: 0 30px;
`;
