import { IconName } from 'components/atoms/Icon';

export const footerData = {
  contactInfo: [
    {
      icon: 'Address' as IconName,
      label: 'Tschirgantweg 11, 6432 Sautens'
    },
    {
      icon: 'Phone' as IconName,
      label: '+43 (0) 699 17090872'
    },
    {
      icon: 'EmailOutlinedIcon' as IconName,
      label: 'info@physiotherapie-havei.at'
    }
  ],
  socialMedia: [
    {
      icon: 'Facebook' as IconName,
      link: 'https://facebook.com'
    },
    {
      icon: 'Instagram' as IconName,
      link: 'https://instagram.com'
    },
  ],
  moreInfo: [
    {
      title: 'Weitere Links',
      links: [
        {
          label: 'Impressum',
          url: '/impressum'
        },
        {
          label: 'Datenschutzerklärung',
          url: '/datenschutz'
        },
        // {
        //   label: 'Awards',
        //   url: 'https://google.com'
        // },
        // {
        //   label: 'Experience',
        //   url: 'https://google.com'
        // },
      ]
    },
  ]
}