import { slide as BurgerMenu } from 'react-burger-menu';
import { styles } from './styled';
import { StyledMenuItem } from 'components/atoms';
import { IRouteList } from "types";

interface BurgerSlideMenuProps {
    menuItems: IRouteList
}
const blacklistRoutes = ["*", "/impressum", "/datenschutz"];

const BurgerSlideMenu = ({ menuItems }: BurgerSlideMenuProps) => (
    <BurgerMenu styles={ styles } pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" } right>
        {menuItems.map((r, idx) => {
            return !blacklistRoutes.includes(r.path) ? <StyledMenuItem key={`${r.displayName}_${idx}`} to={r.path}>
                {r.displayName}
            </StyledMenuItem> : null;
        })}
    </BurgerMenu>
);

export default BurgerSlideMenu;