import styled from "@emotion/styled";
import { StyledButton } from 'components/atoms';

const StyledPriceBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 280px;
  justify-content: flex-start;
  padding: 35px;
  background-color: #d8f3dc;
  border-radius: 7px;
  .price-box-title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 50px;
  }
  .price-box-price {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .price-box-descr {
    font-size: 22px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
  }
  button {
    margin-top: 75px;
    padding: 10px 12px;
    font-size: 18px;
  }
`;

interface PriceBoxProps {
    title: string;
    price: string;
    bookFn: () => void;
    description: string;
}

const PriceBox = ({ title, price, description, bookFn }: PriceBoxProps) => (
    <StyledPriceBox>
        <span className="price-box-title">{title}</span>
        <span className="price-box-price">{price}</span>
        <p className="price-box-descr">{description}</p>
        <StyledButton className={'secondary'} onClick={bookFn}>jetzt buchen</StyledButton>
    </StyledPriceBox>
);


export default PriceBox;