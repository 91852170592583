import styled from "@emotion/styled";
import {TextField, TextFieldProps} from "@mui/material";
import { forwardRef } from 'react';


const TextInput = forwardRef((props:TextFieldProps, ref) => (
    <TextField {...props} inputRef={ref} variant="outlined" />
));

export const TextInputField = styled(TextInput)`
  .MuiInputLabel-root {
    &.Mui-focused {
      
    }
  }
  //.MuiOutlinedInput-root.Mui-error {
  //  color: red;
  //}
  //.MuiInputBase-root {
  //  border-radius: 0;
  //}
`;