import styled from "@emotion/styled";
import { NavLink, NavLinkProps } from 'react-router-dom';

const MenuItem = ({ className, to, children }: NavLinkProps) => (
    <NavLink className={className} to={to}>{children}</NavLink>
)


export const StyledMenuItem = styled(MenuItem)`
  width: 100%;
  color: #ffffff;
  text-decoration: none;
  text-align: left;
  transition: all .3s;
  border-bottom: 2px solid transparent;
  &.active {
    border-bottom-color: #E89A5E;
  }
  &:hover {
    text-underline: none;
    border-bottom-color: #E89A5E;
  }
  padding: 1em;
  &:focus {
    outline: none;
  }
`;