import { PageTemplate } from 'components/templates';
import { PassageTitle, Listing } from "components/atoms";
import { StyledMobileTherapy } from './styled';

const MobileTherapy = () => (
    <PageTemplate
        titleText={'Mobile Therapie'}
        subTitleText={'Wie kommen Sie zur mobilen Physiotherapie Havei?'}
    >
        <StyledMobileTherapy className="inner-content">
            <PassageTitle titleText={'Physiotherapie Verordnung vom Arzt - Zuweisung "Physiotherapie Havei"'} />
            <p>
                Sie benötigen eine ärztliche Verordnung für die Physiotherapie (Heilgymnastik) als Hausbesuch. Sprechen
                Sie hierfür mit Ihrem überweisenden Arzt.
                In der Regel haben sich folgende Verordnungen bewährt:

            </p>
            <Listing items={[
                '45 Minuten Physiotherapie + 15 Minuten Massage - Hausbesuch erbeten',
                '60 Minuten neurophysiologische Physiotherapie - Hausbesuch erbeten'
            ]} />
            <br/>
            <br/>
            <PassageTitle titleText={'Bewilligung der Verordnung bei Ihrer zuständigen Krankenkasse'} />
            <p>
                Je nach Krankenkassa (z.B. ÖGK) müssen Sie Ihre Verordnung chefärztlich bewilligen lassen. Dies kann
                persönlich, per Fax oder durch Ihren Arzt erfolgen.
                Gerne kann ich das für Sie übernehmen

            </p>
            <br/>
            <br/>
            <PassageTitle titleText={'Überprüfen Sie Ihren Standort'} />
            <p>
                Für Ihre Therapie besuche ich Sie im <span className="">Raum Innsbruck, Innsbruck-Land und Raum Imst</span>.
                In Ausnahmefällen kann ich für Sie <span className="">nach Vereinbarung</span> auch weitere Bezirke abdecken.
            </p>
            <br/>
            <br/>
            <PassageTitle titleText={'Termin vereinbaren'} />
            <Listing items={[
                'Sie erreichen mich telefonisch oder per E-Mail oder über das Kontaktformular.',
                'Bitte haben Sie Verständnis, wenn es mir einmal nicht möglich ist, auf Ihren Anruf zu antworten. Ich werde Sie schnellstmöglich zurückrufen.',
                'Bitte teilen Sie mir spätestens 24 Stunden vorher mit, wenn Sie einen Termin nicht einhalten können.',
                'Bei einer zu späten Absage oder bei Nichterscheinen zu einem Termin, wird die Therapieeinheit aus organisatorischen Gründen in Rechnung gestellt.'
            ]} />
            <br/>
            <br/>
            <PassageTitle titleText={'Therapie starten'} />
            <p>
                Halten Sie die Verordnung, Ihre Befunde und evtl. vorhandene Röntgen- und MRT-Bilder bereit.
                Tragen Sie bequeme Kleidung - das sorgt für mehr Wohlbefinden bei Ihrer Therapie.
            </p>
            <br/>
        </StyledMobileTherapy>
    </PageTemplate>
);

export default MobileTherapy;