import * as Icons from './icons';

export type IconName = "Phone" |
    "Address" |
    "Clock" |
    "Facebook" |
    "Instagram" |
    "EmailOutlinedIcon" |
    "TickIcon" |
    "LinkedIn";


interface IconProps {
  name: IconName
  style?: { [key: string]: any }
}


const Icon = ({ name, style }: IconProps) => {
  const ChosenIcon = Icons[name];
  return ChosenIcon ? (
    <ChosenIcon {...{ style: { display: 'block', ...style } }} />
  ) : null;
};


export default Icon;