import styled from "@emotion/styled";

export const StyledLoginViewContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledFormInput = styled.div`
  padding: 15px 10px;
  width: 100%;
  .MuiFormControl-root {
    width: 100%;
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  max-width: 350px;
  .MuiButton-root {
    width: 100%;
  }
`;