import styled from "@emotion/styled";

export const StyledTherapyOffers = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 50px auto 70px;
  section {
    display: flex;
    justify-content: space-around;
    margin-bottom: 160px;
    &:last-of-type {
      margin-bottom: 90px;
    }
    .image-polaroid-frame {
      width: auto;
      height: 380px;
      box-shadow: none;
      border: none;
      flex-direction: column;
      p {
        position: unset;
      }
      img {
        border-radius: 8px;
      }
    }
    li {
      //line-height: 2.5;
    }
    .offers-text-content {
      padding: 0 10px;
    }
  }
  @media (max-width: 768px) {
    margin: 30px auto 50px;
    section {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 40px;
      &:last-of-type {
        margin-bottom: 30px;
      }
      .image-polaroid-frame {
        height: auto;
      }
      li {
        //line-height: 2.2;
      }
      .offers-text-content {
        margin-bottom: 10px;
      }
      &.my-specialty {
        flex-direction: column-reverse;
      }
    }
  }
`;