import { StyledNav } from "./styled";
import { StyledNavBarItem } from 'components/atoms';
import { IRouteList} from "types";

interface NavBarProps {
    navItems: IRouteList
}

const blacklistRoutes = ["*", "/impressum", "/datenschutz"];

const NavBar = ({ navItems }: NavBarProps) => (
    <StyledNav>
        {navItems.map((r, idx) => {
            return !blacklistRoutes.includes(r.path) ? <StyledNavBarItem key={`${r.displayName}_${idx}`} to={r.path}>
                {r.displayName}
            </StyledNavBarItem> : null;
        })}
    </StyledNav>
);

export default NavBar;