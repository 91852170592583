import { PageTemplate } from 'components/templates';
import { StyledAboutMe } from './styled';
import { PassageTitle, Listing } from "components/atoms";
import ABOUT_IMG from "assets/images/img_4.JPG";

const AboutMe = () => (
    <PageTemplate
        titleText={'Über mich'}
        subTitleText={'Claudiu Havei - Mobiler Physiotherapeut '}
    >
        <StyledAboutMe className="inner-content">
            <section className="info">
                <div className="info__text">
                    <p>
                        Mein Name ist <strong>Claudiu Havei</strong> - ich bin ausgebildeter Pfleger und diplomierter Physiotherapeut -
                        wohne in Sautens und bin verheiratet. Neben der Arbeit mit Menschen ist Sport meine zweite
                        Leidenschaft.
                        Abgesehen von meiner Selbständigkeit arbeite ich auch als diplomierter Physiotherapeut in der
                        Landespflegeklinik in Hall in Tirol.
                    </p>
                    <PassageTitle titleText={'Mein Motto'} />
                    <div>
                        Sei Du die Veränderung, die Du in der Welt sehen möchtest! - Mahatma Gandhi
                    </div>
                </div>
                <div className="info__image">
                    <img src={ABOUT_IMG} alt="Physiotherapie Havei Tirol"/>
                </div>
            </section>
            <section className="passage">
                <p>
                    Entsprechend Ihren individuellen Beschwerden oder Ihrem Krankheitsbild biete ich Ihnen ein
                    umfangreiches Leistungsangebot. Eine rasche, kompetente und vor allem stressfreie
                    physiotherapeutische Behandlung erhöht Ihr Wohlbefinden und damit Ihre Genesungschancen.
                    Die speziellen Bedürfnisse sowie der Umgang von geriatrischen und schwerstpflegebedürftigen
                    Patientinnen und Patienten sind mir aufgrund meiner jahrelangen beruflichen Erfahrung als Pfleger
                    und Physiotherapeut besonders vertraut.

                </p>
            </section>
            <section className="trainings">
                <PassageTitle titleText={'Ausbildungen'} />
                <Listing items={[
                    'Pflegeassistent am Ausbildungszentrum West in Innsbruck',
                    'Diplom an der EURAK in Hall in Tirol'
                ]} />
                <div className="diploma">
                    Diplomarbeit „Einfluss der craniomandibulären Dysfunktion auf die Körperhaltung“
                    Fortbildungen
                </div>

            </section>
            <section className="certifications">
                <PassageTitle titleText={'Fortbildungen'} />
                <Listing items={[
                    'Manuelle Lymphdrainage nach Dr. Vodder',
                    'Neurokognitive Rehabilitation nach Prof. Carlo Perfetti',
                    'Funktionelle Bewegungslehre Klein-Vogelbach',
                    'Manuelle Therapie nach Maitland und Kaltenborn',
                    'Aquatherapie'
                ]} />
            </section>
        </StyledAboutMe>
    </PageTemplate>
);

export default AboutMe;