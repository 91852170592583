import styled from '@emotion/styled';

export const StyledHomeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 80px;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
  section {
    display: flex;
    width: 100%;
    align-items: flex-start;
    &.intro {
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }
    &.statement {
      flex-direction: column;
      div {
        max-width: 400px;
        text-align: center;
        margin: 90px auto 50px;
        font-weight: 300;
        line-height: 1.6;
      }
      p {
        text-align: center;
        margin: 0 auto 80px;
        font-weight: 300;
        line-height: 1.9;
        max-width: 800px;
        .bold {
          font-weight: bold;
        }
      }
      @media (max-width: 768px) {
        div {
          max-width: 350px;
          margin: 70px auto;
        }
        p {
          max-width: 350px;
          margin-bottom: 50px;
        }
      }
    }
    &.services {
      margin-bottom: 70px;
      justify-content: space-around;
      .services__image {
        height: 350px;
        border-radius: 16px;
        img {
          height: 100%;
          border-radius: 16px;
        }
      }
      .services__contact {
        li {
          text-align: left;
          font-weight: 300;
          a {
            font-size: 14px;
            color: #2d6a4f;
            text-decoration: none;
            font-weight: normal;
            line-height: 1.75;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
        .services__contact {
          margin-bottom: 20px;
          text-align: left;
        }
      }
    }
  }
`;

export const StyledHomeTextWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 441px;
  align-items: flex-start;
  margin-right: 15px;
  
  .home-title {
    font-size: 46px;
    text-decoration: underline;
    color: #E89A5E;
    margin-bottom: 24px;
    text-align: left;
  }
  .home-sub-title {
    color: #2d6a4f;
    font-size: 22px;
    span {
      font-style: italic;
      font-size: 16px;
      margin-top: 10px;
      display: block;
      font-weight: 400;
    }
  }
  @media (max-width: 768px) {
    margin-right: 0;
    .home-title {
      font-size: 36px;
      align-items: center;
      text-align: center;
      max-width: 300px;
      margin: 0 auto 15px;
    }
    .home-sub-title {
      text-align: center;
      font-size: 18px;
      width: 100%;
      span {
        font-size: 14px;
      }
    }
  }
  .home-appointment {
    align-self: flex-start;
    margin-top: 50px;
    button {
      // background-color: #40916c; //#2d6a4f; // #FF662D
      height: 48px;
      width: 212px;
      color: #fff;
      border-radius: 8px;
      box-shadow: 0 15px 30px 0 rgba(64, 145, 108, 0.32);
    }
    @media (max-width: 768px) {
      align-self: center;
      margin: 30px auto;
    }
  }
`;

export const StyledHomeImgWrapper = styled.div`
  display: flex;
  width: 550px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: 10px solid #ffffff;
  border-bottom: 64px solid #fff;
  position: relative;
  
  img {
    height: 100%;
    width: 100%;
  }
  p {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: -64px;
    font: 400 22px/1 'Kaushan Script',cursive;
    color: #2d6a4f;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    width: 350px;
    border-bottom: 45px solid #fff;
    p {
      bottom: -45px;
      font: 400 16px/1 'Kaushan Script',cursive;
    }
  }
  @media (max-width: 280px) {
    width: 250px;
    border-bottom: 45px solid #fff;
    p {
      bottom: -45px;
      font: 400 14px/1 'Kaushan Script',cursive;
    }
  }
`;