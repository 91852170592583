import styled from "@emotion/styled";

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledFormWrapper = styled.div`
  max-width: 400px;
  width: 100%;
`;

export const StyledForm = styled.form`
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.06);
  padding: 30px;
  border: 1px solid #eeeeee;
  
  & > div {
    margin: 10px 0;
  }
  .form-title {
    font-size: 28px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1b4332;
  }
  button {
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
  &.form-sending {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }
`;
export const StyledTextWrapper = styled.div`
  max-width: 550px;
  width: 100%;
  margin-right: 30px;
  padding: 0 24px;
  h2 {
    color: #1b4332;
    margin-bottom: 40px;
  }
  h3 {
    color: #1b4332;
    margin-bottom: 5px;
  }
  p {
    line-height: 1.8;
  }
  .signed-name {
    display: block;
    font-weight: bold;
    color: #1b4332;
  }
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

export const StyledSuccessMessage = styled.div`
  max-width: 480px;
  width: 100%;
  min-height: 500px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.06);
  padding: 30px;
  border: 1px solid #eeeeee;
  .icon-wrapper {
    margin-bottom: 30px;
    color: #52b788;
  }
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;