import styled from "@emotion/styled";

export const StyledAboutMe = styled.div`
  margin: 50px auto 70px;
  .info {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    &__text {
      background-color: #2d6a4f;
      padding: 20px;
      flex: 2;
      height: 300px;
      color: #ffffff;
      border-top-left-radius:  16px;
      border-bottom-left-radius: 16px;
      border-right: 4px solid #ffffff;
      p {
        line-height: 1.9;
        font-size: 16px;
      }
      h2 {
        color: #ffffff;
        font-size: 16px;
        &:before {
          width: 80px;
        }
      }
      div {
        font-style: italic;
        font-weight: 300;
      }
    }
    &__image {
      //height: 600px;
      height: 100%;
      width: 100%;
      flex: 1;
      img {
        height: 100%;
        width: 100%;
        border-radius: 18px;
      }
    }
  }
  section {
    text-align: left;
    
    &.passage {
      p {
        text-align: center;
        margin: 0 auto 80px;
        font-weight: 300;
        line-height: 1.9;
        font-size: 18px;
        max-width: 800px;
      }
    }
    &.trainings {
      margin-bottom: 50px;
      .diploma {
        padding-left: 20px;
        font-weight: 300;
        font-style: italic;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 768px) {
    margin: 30px auto 70px;
    .info {
      flex-direction: column-reverse;
      align-items: center;
      margin: 0 auto 40px;
      &__text {
        padding-top: 10px;
        border-top-right-radius:  16px;
        border-bottom-right-radius: 16px;
        border-right: none;
        p {
          text-align: center;
        }
      }
      &__image {
        margin-bottom: 10px;
      }
    }
  }
`;