import { useNavigate, Link } from "react-router-dom";
import { PageTemplate } from 'components/templates';
import {Listing, PassageTitle, StyledButton} from 'components/atoms';
import { StyledHomeContainer, StyledHomeTextWrapper, StyledHomeImgWrapper } from './styled';
import HOME_IMG from 'assets/images/img_3_1.JPG';
import PERSON_PHONE from 'assets/images/person_using_phone.jpg';


const Home = () => {
    const navigate = useNavigate();
    const appointmentBtnClickFn = () => {
        navigate('/kontakt#contactForm');
    };

    return (
        <PageTemplate titleText={'Startseite'}>
            <StyledHomeContainer className="inner-content">
                <section id="homeIntro" className="intro">
                    <StyledHomeTextWrapper>
                        <div className="home-title">Mobile Physiotherapie Havei</div>
                        <div className="home-sub-title">
                            - für den Raum Innsbruck, Innsbruck-Land und Imst
                        </div>
                        <div className="home-appointment">
                            <StyledButton onClick={appointmentBtnClickFn}>
                                Termin vereinbaren
                            </StyledButton>
                        </div>
                    </StyledHomeTextWrapper>
                    <StyledHomeImgWrapper>
                        <img src={HOME_IMG} alt='physiotherapist_image' />
                        <p>Physiotherapeut Claudiu Havei</p>
                    </StyledHomeImgWrapper>
                </section>
                <section id="homeStatement" className="statement">
                    <div>
                        Als diplomierter Physiotherapeut
                        nehme ich mir Zeit für Ihre Gesundheit.
                        Ich bin mobil und komme zu Ihnen -
                        damit es Ihnen bald besser geht!
                        Termine werden schnell und unkompliziert vereinbart.
                    </div>
                    <p>
                        Sie brauchen eine <span className="bold">gezielte physiotherapeutische Therapie </span>und eine
                        <span className="bold"> flexible Termingestaltung</span>.
                        Für Sie sind die An- und Abreise oder lange Wartezeiten beschwerlich. Gerne komme ich zu Ihnen
                        nach Hause oder in Ihr Büro um Sie in Ihren Einschränkungen bestmöglich zu unterstützen.
                        Ich bin auf <span className="bold">Hausbesuche</span> spezialisiert und betreue Personen
                        <span className="bold"> jeder Generation</span> mit jeglichen
                        <span className="bold"> physiotherapeutischen</span> Anliegen. Meine
                        <span className="bold"> Behandlungsliege</span> und das
                        notwendige Equipment bringe ich selbstverständlich mit.<br />
                        Für Ihre Therapie besuche ich Sie im
                        <span className="bold"> Raum Innsbruck, Innsbruck-Land und Imst</span>. In Ausnahmefällen
                        kann ich für Sie <span className="bold">nach Vereinbarung</span> auch weitere Bezirke abdecken.
                    </p>

                </section>
                <section id="homeServices" className="services">
                    <div className="services__image">
                        <img src={PERSON_PHONE} alt="contact us"/>
                    </div>
                    <div className="services__contact">
                        <PassageTitle titleText={'Sie können Termine bequem vereinbaren'} />
                        <Listing items={
                            [
                                <>telefonisch unter <a href="tel:+4369917090872">+43 (0) 699 17090872</a></>,
                                <>per E-Mail <a href="mailto:info@physiotherapie-havei.at">info@physiotherapie-havei.at</a></>,
                                <>mit <Link to="/kontakt#contactForm">Kontaktformular</Link></>
                            ]
                        } />
                    </div>
                </section>
            </StyledHomeContainer>
        </PageTemplate>
    );
};

export default Home;