import { EmailOutlined } from '@mui/icons-material';

interface IconAttrProps {
  height?: string
  width?: string
  style?: { [key: string]: any }
}

export const EmailOutlinedIcon = ({ style }: IconAttrProps) => <EmailOutlined {...style} />;

export const Clock = ({ height, width, style }: IconAttrProps) => (
  <svg width={width || '17px'} height={height || '17px'} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" {...style}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="03_Our_serivices" transform="translate(-136.000000, -1997.000000)" stroke="#FFFFFF">
        <g id="Footer" transform="translate(1.000000, 1852.000000)">
          <g id="Information" transform="translate(135.331195, 60.000000)">
            <g id="Time" transform="translate(0.002139, 83.000000)">
              <g id="Group" transform="translate(0.000000, 2.334000)">
                <g id="Group" transform="translate(0.666667, 0.666667)">
                  <circle id="Oval" cx="7.33333333" cy="7.33333333" r="7.33333333"></circle>
                  <line x1="11.3333333" y1="11.3333333" x2="8" y2="8" id="Shape"></line>
                  <circle id="Oval" cx="7.33333333" cy="7.33333333" r="1"></circle>
                  <line x1="7.33333333" y1="4" x2="7.33333333" y2="6.66666667" id="Shape"></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const Address = ({height, width, style }: IconAttrProps) => (
  <svg width={width || '17px'} height={height || "16px"} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" {...style}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0" strokeLinecap="round" strokeLinejoin="round">
      <g id="03_Our_serivices" transform="translate(-136.000000, -1914.000000)" fill="#FFFFFF" stroke="#FFFFFF">
        <g id="Footer" transform="translate(1.000000, 1852.000000)">
          <g id="Information" transform="translate(135.331195, 60.000000)">
            <g id="Address" transform="translate(0.002139, 0.000000)">
              <g id="Group" transform="translate(0.000000, 2.000000)">
                <g id="Group" transform="translate(0.666667, 0.666667)">
                  <line x1="6" y1="8.66666667" x2="0" y2="14.6666667" id="Shape"></line>
                  <path d="M13.3289865,5.18566817 C13.4140234,5.27073078 13.5293736,5.31852061 13.6496521,5.31852061 C13.7699307,5.31852061 13.8852809,5.27073078 13.9703178,5.18566817 L14.4788935,4.67709243 C14.7292577,4.42665264 14.7292577,4.02068972 14.4788935,3.77024993 L10.8964167,0.187773125 C10.645977,-0.0625910418 10.240014,-0.0625910418 9.98957424,0.187773125 L9.4809985,0.696348869 C9.39593589,0.781385782 9.34814606,0.896736009 9.34814606,1.01701453 C9.34814606,1.13729306 9.39593589,1.25264328 9.4809985,1.3376802 C9.5660611,1.42271711 9.61385094,1.53806734 9.61385094,1.65834586 C9.61385094,1.77862439 9.5660611,1.89397461 9.4809985,1.97901153 L6.7829176,4.67709243 C6.66267263,4.79737372 6.49957409,4.86496618 6.32949635,4.86500251 L4.29519337,4.86500251 C4.12511563,4.86503883 3.96201709,4.9326313 3.84177212,5.05291259 L3.52110646,5.37357825 C3.27074229,5.62401804 3.27074229,6.02998096 3.52110646,6.28042075 L8.38624592,11.1455602 C8.63668571,11.3959244 9.04264863,11.3959244 9.29308842,11.1455602 L9.61375408,10.8248945 C9.73403537,10.7046496 9.80162784,10.541551 9.80166416,10.3714733 L9.80166416,8.33781165 C9.80170048,8.16773391 9.86929295,8.00463536 9.98957424,7.8843904 L12.6876551,5.1863095 C12.772607,5.1011618 12.8879094,5.05325658 13.008188,5.0531363 C13.1284665,5.05301602 13.2438645,5.10069055 13.3289865,5.18566817 Z" id="Shape"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const Phone = ({height, width, style }: IconAttrProps) => (
  <svg width={width || '17px'} height={height || "16px"} viewBox="0 0 17 16" version="1.1" xmlns="http://www.w3.org/2000/svg" {...style}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="03_Our_serivices" transform="translate(-136.000000, -1956.000000)" stroke="#FFFFFF">
        <g id="Footer" transform="translate(1.000000, 1852.000000)">
          <g id="Information" transform="translate(135.331195, 60.000000)">
            <g id="Phone" transform="translate(0.000000, 42.000000)">
              <g id="Group" transform="translate(0.000000, 2.000000)">
                <g id="Group" transform="translate(0.666667, 0.666667)">
                  <path d="M10,14 L10.5925926,14 C11.7386667,14 12.6666667,13.3120567 12.6666667,12.065764 L12.6666667,10.6666667" id="Shape"></path>
                  <path d="M12,5.33333333 C13.2983677,5.33239472 14.4083706,6.28857106 14.6280129,7.597152 C14.8476552,8.90573294 14.1129821,10.1856899 12.8886207,10.6275435 C12.6847746,10.701409 12.4585488,10.669481 12.2818889,10.5419132 C12.1052289,10.4143454 12.0001239,10.2070166 12,9.98586253 L12,5.33333333 Z" id="Shape"></path>
                  <path d="M2.66666667,5.33333333 C1.36829894,5.33239472 0.258296037,6.28857106 0.0386537643,7.597152 C-0.180988508,8.90573294 0.553684617,10.1856899 1.77804601,10.6275435 C1.9818921,10.701409 2.20811782,10.669481 2.38477781,10.5419132 C2.5614378,10.4143454 2.66654274,10.2070166 2.66666667,9.98586253 L2.66666667,5.33333333 Z" id="Shape"></path>
                  <path d="M12,6 L12,4.73684211 C12,2.12075645 9.91066217,0 7.33333333,0 C4.7560045,0 2.66666667,2.12075645 2.66666667,4.73684211 L2.66666667,6" id="Shape"></path>
                  <path d="M7.27272727,13.3333333 L9.6969697,13.3333333 C9.86432871,13.3333333 10,13.4825718 10,13.6666667 L10,14.3333333 C10,14.5174282 9.86432871,14.6666667 9.6969697,14.6666667 L7.27272727,14.6666667 C6.93800924,14.6666667 6.66666667,14.3681898 6.66666667,14 L6.66666667,14 C6.66666667,13.6318102 6.93800924,13.3333333 7.27272727,13.3333333 Z" id="Shape"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const Facebook = ({height, width, style }: IconAttrProps) => (
  <svg width={width || '24px'} height={height || "24px"} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...style}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="03_Our_serivices" transform="translate(-136.000000, -2074.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="Footer" transform="translate(1.000000, 1852.000000)">
          <g id="Shape" transform="translate(135.000000, 222.000000)">
            <path d="M19.55,14.56 C19.5194883,14.8124825 19.3043128,15.0018369 19.05,15 L17,15 C16.7238576,15 16.5,15.2238576 16.5,15.5 L16.5,23.5 C16.5,23.7761424 16.7238576,24 17,24 L23,24 C23.5522847,24 24,23.5522847 24,23 L24,1 C24,0.44771525 23.5522847,0 23,0 L1,0 C0.44771525,0 0,0.44771525 0,1 L0,23 C0,23.5522847 0.44771525,24 1,24 L12,24 C12.2761424,24 12.5,23.7761424 12.5,23.5 L12.5,15.5 C12.5,15.2238576 12.2761424,15 12,15 L10,15 C9.72385763,15 9.5,14.7761424 9.5,14.5 L9.5,11.5 C9.5,11.2238576 9.72385763,11 10,11 L12,11 C12.2761424,11 12.5,10.7761424 12.5,10.5 L12.5,9.19 C12.5,6.04749977 15.0474998,3.5 18.19,3.5 L19.5,3.5 C19.6326082,3.5 19.7597852,3.55267842 19.8535534,3.64644661 C19.9473216,3.7402148 20,3.86739176 20,4 L20,7 C20,7.27614237 19.7761424,7.5 19.5,7.5 L18.19,7.5 C17.2566388,7.5 16.5,8.25663877 16.5,9.19 L16.5,10.5 C16.5,10.7761424 16.7238576,11 17,11 L19.43,11 C19.5736899,10.9989612 19.7108812,11.0597925 19.8065835,11.166979 C19.9022857,11.2741656 19.947247,11.4173451 19.93,11.56 L19.55,14.56 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const Instagram = ({height, width, style }: IconAttrProps) => (
  <svg width={width || '24px'} height={height || "24px"} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...style}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="03_Our_serivices" transform="translate(-200.000000, -2074.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="Footer" transform="translate(1.000000, 1852.000000)">
          <g id="Social" transform="translate(135.000000, 222.000000)">
            <g id="Shape" transform="translate(64.000000, 0.000000)">
              <path d="M17.5,0 L6.5,0 C2.91243253,0.0055066367 0.0055066367,2.91243253 0,6.5 L0,17.5 C0.0055066367,21.0875675 2.91243253,23.9944934 6.5,24 L17.5,24 C21.0875675,23.9944934 23.9944934,21.0875675 24,17.5 L24,6.5 C23.9944934,2.91243253 21.0875675,0.0055066367 17.5,0 Z M12,17.5 C8.96243388,17.5 6.5,15.0375661 6.5,12 C6.5,8.96243388 8.96243388,6.5 12,6.5 C15.0375661,6.5 17.5,8.96243388 17.5,12 C17.5,13.4586907 16.9205374,14.8576372 15.8890873,15.8890873 C14.8576372,16.9205374 13.4586907,17.5 12,17.5 Z M18.5,6.5 C17.6715729,6.5 17,5.82842712 17,5 C17,4.17157288 17.6715729,3.5 18.5,3.5 C19.3284271,3.5 20,4.17157288 20,5 C20,5.39782473 19.8419647,5.7793556 19.5606602,6.06066017 C19.2793556,6.34196474 18.8978247,6.5 18.5,6.5 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const LinkedIn = ({height, width, style }: IconAttrProps) => (
  <svg width={width || '24px'} height={height || "25px"} viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...style}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="03_Our_serivices" transform="translate(-266.000000, -2075.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="Footer" transform="translate(1.000000, 1852.000000)">
          <g id="Social" transform="translate(135.000000, 222.000000)">
            <g id="Combined-Shape" transform="translate(130.000000, 1.500000)">
              <path d="M5.76086957,8 C6.03701194,8 6.26086957,8.22385763 6.26086957,8.5 L6.26086957,23.5 C6.26086957,23.7761424 6.03701194,24 5.76086957,24 L0.5,24 C0.223857625,24 2.7768245e-13,23.7761424 2.76667578e-13,23.5 L2.76667578e-13,8.5 C2.7663376e-13,8.22385763 0.223857625,8 0.5,8 L5.76086957,8 Z M17.5797631,7.00064919 L17.8474916,7.00330188 C21.4354515,7.00330188 24,9.1484024 24,13.5675439 L24,13.5675439 L24,23.413907 C24,23.7375972 23.7304724,24 23.3979933,24 L23.3979933,24 L19.7859532,24 C19.4534741,24 19.1839465,23.7375972 19.1839465,23.413907 L19.1839465,23.413907 L19.1839465,14.6225114 C19.1839465,13.0040601 17.8363086,11.6920462 16.173913,11.6920462 C14.5115175,11.6920462 13.1638796,13.0040601 13.1638796,14.6225114 L13.1638796,14.6225114 L13.1638796,23.413907 C13.1638796,23.7375972 12.894352,24 12.5618729,24 L12.5618729,24 L8.94983278,24 C8.61735366,24 8.34782609,23.7375972 8.34782609,23.413907 L8.34782609,23.413907 L8.34782609,8.17548795 C8.34782609,7.85179771 8.61735366,7.58939491 8.94983278,7.58939491 L8.94983278,7.58939491 L12.5618729,7.58939491 C12.894352,7.58939491 13.1638796,7.85179771 13.1638796,8.17548795 C13.1630355,8.37653924 13.2908273,8.55685422 13.4841905,8.62744863 C13.6775536,8.69804304 13.8956176,8.64399553 14.0307692,8.49197819 C15.0298863,7.49312915 16.4163699,6.9523444 17.8474916,7.00330188 Z M3.13043478,5.68434189e-14 C4.85932617,5.68434189e-14 6.26086957,1.34314575 6.26086957,3 C6.26086957,4.65685425 4.85932617,6 3.13043478,6 C1.40154339,6 2.76667578e-13,4.65685425 2.76667578e-13,3 C2.76667578e-13,1.34314575 1.40154339,5.68434189e-14 3.13043478,5.68434189e-14 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const TickIcon = (attrs: IconAttrProps) => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...attrs}>
      <g>
        <g>
          <path
              d="M497.36,69.995c-7.532-7.545-19.753-7.558-27.285-0.032L238.582,300.845l-83.522-90.713
			c-7.217-7.834-19.419-8.342-27.266-1.126c-7.841,7.217-8.343,19.425-1.126,27.266l97.126,105.481
			c3.557,3.866,8.535,6.111,13.784,6.22c0.141,0.006,0.277,0.006,0.412,0.006c5.101,0,10.008-2.026,13.623-5.628L497.322,97.286
			C504.873,89.761,504.886,77.54,497.36,69.995z"
              fill="currentColor"
          />
        </g>
      </g>
      <g>
        <g>
          <path
              d="M492.703,236.703c-10.658,0-19.296,8.638-19.296,19.297c0,119.883-97.524,217.407-217.407,217.407
			c-119.876,0-217.407-97.524-217.407-217.407c0-119.876,97.531-217.407,217.407-217.407c10.658,0,19.297-8.638,19.297-19.296
			C275.297,8.638,266.658,0,256,0C114.84,0,0,114.84,0,256c0,141.154,114.84,256,256,256c141.154,0,256-114.846,256-256
			C512,245.342,503.362,236.703,492.703,236.703z"
              fill="currentColor"
          />
        </g>
      </g>
    </svg>
);