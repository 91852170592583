import styled from "@emotion/styled";
import { PageTemplate } from 'components/templates';

const StyledDataPrivacy = styled.div`
  text-align: left;
  font-size: 14px;
  a {
    color: #2d6a4f;
    text-decoration: none;
    font-weight: normal;
    line-height: 1.75;
    &:hover {
      text-decoration: underline;
    }
  }
  h5 {
    font-size: 14px;
  }
`;

const DataPrivacy = () => (
    <PageTemplate
        titleText={'Datenschutzerklärung'}
        subTitleText={'Erklärung zur Informationspflicht'}
    >
        <StyledDataPrivacy className={'inner-content'}>
            <p> In folgender Datenschutzerklärung informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung
                im Rahmen unserer Webseite. Wir erheben und verarbeiten personenbezogene Daten nur auf Grundlage der
                gesetzlichen Bestimmungen (Datenschutzgrundverordnung, Telekommunikationsgesetz 2003). </p>
            <p> Sobald Sie als Benutzer auf unsere Webseite zugreifen oder diese besuchen wird Ihre IP-Adresse, Beginn
                sowie Beginn und Ende der Sitzung erfasst. Dies ist technisch bedingt und stellt somit ein berechtigtes
                Interesse iSv Art 6 Abs 1 lit f DSGVO. </p>
            <h5>Kontakt mit uns</h5>
            <p>Wenn Sie uns, entweder über unser Kontaktformular auf unserer Webseite, oder per Email kontaktieren,
                dann werden die von Ihnen an uns übermittelten Daten zwecks Bearbeitung Ihrer Anfrage oder für den Fall
                von weiteren Anschlussfragen für sechs Monate bei uns gespeichert. Es erfolgt, ohne Ihre Einwilligung,
                keine Weitergabe Ihrer übermittelten Daten.</p>
                <h5>Cookies</h5>
                <p>Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit
                    Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. Wir nutzen
                    Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät
                    gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch
                    wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er
                    Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der
                    Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein. </p>
                    <h5>Newsletter</h5>
            <p>Sie haben die Möglichkeit, über unsere Website unseren Newsletter zu
                        abonnieren. Hierfür benötigen wir Ihre E-Mail-Adresse und ihre Erklärung, dass Sie mit dem Bezug
                        des Newsletters einverstanden sind.</p>
            <h5>Ihre Rechte als Betroffener</h5>
            <p>Sie als Betroffener haben bezüglich Ihrer Daten, welche bei uns gespeichert sind grundsätzlich ein Recht auf:</p>
                    <ul>
                        <li>Auskunft</li>
                        <li>Löschung der Daten</li>
                        <li>Berichtigung der Daten</li>
                        <li>Übertragbarkeit der Daten</li>
                        <li>Wiederruf und Widerspruch zur Datenverarbeitung</li>
                        <li>Einschränkung</li>
                    </ul>
                    <p>Wenn sie vermuten, dass im Zuge der Verarbeitung Ihrer Daten Verstöße gegen das Datenschutzrecht
                        passiert sind, so haben Sie die Möglichkeit sich bei uns (info@physiotherapie-havei.at) oder der
                        Datenschutzbehörde zu beschweren.</p>
            <h5>Sie erreichen uns unter folgenden Kontaktdaten:</h5>
                    <p>
                        <b>Webseitenbetreiber:</b> Physiotherapie Havei Tirol<br />
                        <b>Telefonnummer:</b> <a href="tel:+4369917090872">+4369917090872</a><br />
                        <b>Email:</b> <a href="mailto:info@physiotherapie-havei.at">info@physiotherapie-havei.at</a>
                    </p>
                    <p style={{fontSize: '12px'}}>
                        Quelle: <b><a href="https://fairesRecht.at/">Rechtsanwalt</a></b> in Kooperation mit <b>
                        <a href="https://fairemiete.at/">Miete reduzieren faireMiete</a></b>
                    </p>
        </StyledDataPrivacy>
    </PageTemplate>
);


export default DataPrivacy;