import { useMediaQuery } from '@mui/material';
import { BurgerSliderMenu, NavBar } from 'components/molecules';
import { Logo } from 'components/atoms';
import { StyledHeader } from './styled';
import { routes } from "../../../AppRouter";

const Header = () => {
    const isDesktop = useMediaQuery('(min-width: 913px)');
    const isTabletAndDesktop = useMediaQuery('(min-width: 768px)');
    return (
        <StyledHeader className="inner-content">
            <Logo showDescription={isTabletAndDesktop} />
            {isDesktop ? <NavBar navItems={routes} /> : <BurgerSliderMenu menuItems={routes} />}
        </StyledHeader>
    );
};

export default Header;